import React from 'react'
import loadable from '@loadable/component';
import Layout from '../components/layout'
import NewsLetterModule from '../components/NewsLetterModule/NewsLetterModule';
import CareerLandingIntro from '../components/CareerLandingIntro/CareerLandingIntro';
import StickyButtons from '../components/StickyButtons/StickyButtons';
import { graphql } from 'gatsby';
import Seo from '../components/seo';

const BreadcrumbModule = loadable(() => import("../components/BreadcrumbModule/BreadcrumbModule"));
const Banner = loadable(() => import("../components/Banner/Banner"));
const CareersLanding = loadable(() => import("../components/CareersLanding/CareersLanding"));

const CareerLandingTemplate = ({ data }) => {
    const PageData = data?.strapiPage
    const Jobs = data?.allStrapiCareer.edges


    return (
        <Layout footerCta="footercta" popularSearch={PageData?.select_popular_search?.title}>
            <div className="layout-padding-top">
                <BreadcrumbModule
                    parentlabel={PageData.choose_menu[0]?.strapi_parent?.strapi_parent?.title}
                    parentlink={PageData.choose_menu[0]?.strapi_parent?.strapi_parent?.slug}
                    subparentlabel={PageData.choose_menu[0]?.strapi_parent?.title}
                    subparentlink={PageData.choose_menu[0]?.strapi_parent?.slug}
                    title={PageData.title}
                />

                {PageData.banner &&
                    <Banner
                        {...PageData.banner}
                        title={PageData.banner?.banner_title}
                        id={PageData.strapi_id}
                        imagetransforms={PageData.imagetransforms}
                        tag="landing-banner"
                        showTextOnMobile
                        page="career"
                    />
                }

                <div className='career-landing-wrapper layout-grey-bg-theme'>
                    {PageData?.Add_Page_Modules?.length > 0 && PageData.Add_Page_Modules?.map((module, index) => {
                        return (
                            <>
                                {module.strapi_component === "page-modules.text-module" &&
                                    <CareerLandingIntro
                                        shorttext={module.heading}
                                        title={module.title}
                                        desc={module.short_description.data.short_description}
                                    />
                                }
                            </>
                        )
                    })}


                    <CareersLanding allJobs={Jobs} />
                </div>

                <NewsLetterModule />
            </div>

            <StickyButtons tag="careers-page" />
        </Layout>
    )
}

export const Head = ({ data }) => {
    const PageData = data?.strapiPage
    return (
        <Seo title={PageData?.seo?.metaTitle ? PageData?.seo?.metaTitle : PageData?.title} description={PageData?.seo?.metaDescription ? PageData?.seo?.metaDescription : PageData?.title} />
    )
}

export default CareerLandingTemplate

export const query = graphql`
    query ($page_id: String) {
        strapiPage(id: {eq: $page_id}) {
            ...PageFragment
            Add_Page_Modules {
                ... on STRAPI__COMPONENT_PAGE_MODULES_TEXT_MODULE {
                    ...TextModuleFragment
                }
            }
        }

        allStrapiCareer(filter: {publish: {eq: true}}) {
            edges {
                node {
                    ...CareerFragment
                }
            }
        }
    }
`