import React from 'react'
import "./assets/styles/_index.scss"
import { Col, Container, Row } from 'react-bootstrap'
import ScrollAnimation from 'react-animate-on-scroll'
const { ContentModule } = require("@starberry/gatsby-theme-utils/Modules/ContentModule")
const CareerLandingIntro = ({ shorttext, title, desc }) => {
    return (
        <div className='career-landing-intro-wrapper'>
            <Container>
                <Row>
                    <Col xl={7}>
                        <ScrollAnimation animateIn="animate__slideInUp" delay={300} animateOnce offset={10}>
                            <p className='primary-text-line'>{shorttext}</p>
                        </ScrollAnimation>
                        <ScrollAnimation animateIn="animate__slideInUp" delay={600} animateOnce offset={10} className='content'>
                            <h1 className='primary-dark-heading secondary'>{title}</h1>
                            <ContentModule Content={desc} />
                        </ScrollAnimation>
                    </Col>
                    <Col xl={1}></Col>
                    <Col xl={4}></Col>
                </Row>
            </Container>
        </div>
    )
}

export default CareerLandingIntro